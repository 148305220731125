<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/04-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">诚信与人 精心于事</div>
            <div class="head-explain-content">专业服务于中国公共卫生健康产业信息化</div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="industry-box">
                <div class="card">
                    <img src="../assets/image/example/04-0采购协同.png"/>
                    <div class="title1">智慧监管执法</div>
                    <div class="title2">管理透明，高效执行</div>
                    <div class="title3" @click="jumpRout('/purchase-collaboration','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0智能仓储.png"/>
                    <div class="title1">智慧疾病控制</div>
                    <div class="title2">多源、多渠道症状监测数据的汇集</div>
                    <div class="title3" @click="jumpRout('/intelligent-warehousing','2')">了解详情</div>
                </div><div class="card">
                    <img src="../assets/image/example/04-0DPM数字化工单.png"/>
                    <div class="title1">智慧健康体检</div>
                    <div class="title2">一体的智慧体检综合信息平台</div>
                    <div class="title3" @click="jumpRout('/DPM-digital-Sheet','2')">了解详情</div>
                </div><div class="card">
                    <img src="../assets/image/example/04-0数字化生产.png"/>
                    <div class="title1">智慧医防协同</div>
                    <div class="title2">构建电子监控档案教据库、电子病历数据库、全员人口个案数据库</div>
                    <div class="title3" @click="jumpRout('/digital-production','2')">了解详情</div>
                </div><div class="card">
                    <img src="../assets/image/example/04-0采购协同.png"/>
                    <div class="title1">健康监管</div>
                    <div class="title2">健康监督服务实现互联网、微信等新媒体的有机整合，为公众健康服务提供便利</div>
                    <div class="title3" @click="jumpRout('/APS-advanced-scheduling','2')">了解详情</div>
                </div><div class="card">
                    <img src="../assets/image/example/04-0在线安全观察.png"/>
                    <div class="title1">健康体检</div>
                    <div class="title2">公众查询系统能够随时查看自己的体检记录</div>
                    <div class="title3" @click="jumpRout('/safety-observation','2')">了解详情</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {

    },

    methods: {
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.industry-box{
    width: 139rem;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 4rem 0 0 26.1rem;
    .card{
        width: 45rem;
        height: 40rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);

        margin-top: 4rem;
    }
    img{
        width: 41rem;
        height: 22rem;
        margin-top: 2rem;
    }
    .title1{
        height: 2.8rem;
        font-size: 2.8rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        line-height: 4.2rem;
        padding: 2rem;
        text-align: left;
    }
    .title2{
        height: 3.4rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        padding: 2rem;
        padding-top: 1.5rem;
        text-align: left;
    }
    .title3{
        height: 3rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 30px;
        padding-left: 2rem;
        margin-top: 3rem;
        text-align: left;

        cursor: pointer;
    }
    .card:hover{
        transform: scale(1.05);
        transition:all 0.5s;
    }

}
</style>
